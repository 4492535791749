import { useLoaderData } from "@remix-run/react";
import { reuseSeoMeta, reuseHeaders } from "~/utils/misc";
import { PageSingle } from "~/templetes/page-single";
import Preview from "~/components/preview";
import { GenericErrorBoundary } from "~/components/errors";
import type { loader } from "./home.server";
import type { PageSingleQuery } from "~/types/queries";
import { PageAddOns } from "~/components/PageAddOns";

export const meta = reuseSeoMeta;

export const headers = reuseHeaders;

export default function HomePage() {
	const { pageData } = useLoaderData<typeof loader>();

	const { data, query, queryParams, isPreview } = pageData;

	return (
		<Preview
			initialData={data}
			query={query}
			queryParams={queryParams}
			isPreview={isPreview}
		>
			{(currentData) => {
				return (
					<>
						<PageAddOns pageBaseData={currentData} />
						<PageSingle data={currentData as PageSingleQuery} />
					</>
				);
			}}
		</Preview>
	);
}

export function ErrorBoundary() {
	return <GenericErrorBoundary />;
}
